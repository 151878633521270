import React from "react";

import challenges from "../assets/challengesMain.jpg";

const Tackle = () => {
  return (
    <div
      className="my-24 md:px-12 px-4 py-12 max-w-screen-2xl mx-auto bg-white shadow-teal-100 shadow-sm"
      id="working"
    >
      <div className=" text-center mt-3">
        <h2 className=" text-3xl font-bold text-black mb-6 leading-relaxed">
          Challenges We Tackle
        </h2>
      </div>
      <div className="h-96 flex justify-center">
        <img
          className=" h-full rounded-3xl"
          src={challenges}
          alt="challenges"
        />
      </div>
    </div>
  );
};

export default Tackle;
