import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import ContactForm from "../common/ContactForm";

const Contact = () => {
  return (
    <div
      className="md:px-14 p-4 pb-16 max-w-s mx-auto shadow-blue-100 shadow-sm"
      id="contact"
    >
      <div className="flex flex-col lg:flex-row justify-between items-center gap-8">
        <motion.div
          variants={fadeIn("right", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="lg:block flex justify-center"
        >
          {/* <img
              src="https://assets-global.website-files.com/6583d0be13c9647a2c3e2cd5/65966f22321ef63ed30f99c2_mobile%20photo.jpg"
              alt="Steps"
            /> */}
          <div className="lg:flex flex-row w-1/2 font-bold hidden">
            <div className="border-t-2 border-[#08ce8a] w-8 my-auto me-3"></div>
            <h3 className="text-[#08ce8a]"> GET IN TOUCH</h3>
          </div>
          <div className="flex flex-col  justify-center lg:w-1/2 w-full">
            <h2 className="md:text-Sxl text-3xl lg:w-3/4 font-semibold text-textcolor mb-5 leading-normal text-center">
              Send us a message
            </h2>
            <p className="text-black text-lg mb-7 lg:w-3/4 text-center">
              Feel free to reach out to us anytime Our dedicated team is here to
              assist you with any queries or support you may need!
            </p>
          </div>
        </motion.div>

        <motion.div
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="lg:w-1/2"
        >
          <ContactForm />
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
