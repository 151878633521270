import React from "react";

import { motion } from "framer-motion";
import { fadeIn } from "../variants";

//icons
import aiPower from "../assets/icon7.jpg";
import onboarding from "../assets/icon5.jpg";
import experts from "../assets/icon4.jpg";
import products from "../assets/icon1.jpg";
import cust from "../assets/icon2.jpg";
import loan from "../assets/icon3.jpg";

const Features = () => {
  return (
    <div
      className="my-24 md:px-12 pb-10 px-4 max-w-screen-2xl mx-auto bg-white shadow-blue-100 shadow-sm"
      id="about"
    >
      {/* cards */}
      <motion.div
        variants={fadeIn("down", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="text-4xl text-textcolor text-center mb-20"
      >
        About us
      </motion.div>

      <div className="w-full">
        <div className="grid md:grid-col-3 sm:grid-cols-2 grid-cols-1 item-start md:gap-12 gap-8">
          {/* hover:-translate-y-4 transition-all duration-300 Add this line for hover effect */}
          <div className="bg-white rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center  cursor-pointer">
            <div className="flex  lg:flex-row flex-col lg:justify-between lg:w-full lg:h-72">
              <img
                className="w-24 h-24 rounded-3xl mt-5"
                src={loan}
                alt="Image1"
              />
              <div className="lg:w-1/2">
                <h3 className="text-2xl font-bold text-[#08ce8a] mt-5 text-left">
                  Short Term Loans{" "}
                </h3>
                <div className="leading-8 mt-4 text-[#1c1c57]">
                  Delivering short-term secured loans, specifically under 90
                  days in duration, catering to the needs of MSMEs, merchants,
                  and intermediaries.
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center  cursor-pointer">
            <div className="flex  lg:flex-row flex-col lg:justify-between lg:w-full lg:h-72">
              <img
                className="w-24 h-24 rounded-3xl mt-5"
                src={products}
                alt="Image1"
              />
              <div className="lg:w-1/2">
                <h3 className="text-2xl font-bold text-[#08ce8a] mt-5 text-left ">
                  Products{" "}
                </h3>
                <div className="leading-8 mt-4 text-[#1c1c57]">
                  Providing a range of financial products, including invoice
                  discounting, purchase finance, BNPL (Buy Now Pay Later), and
                  EXIM (Export-Import) financing.
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center  cursor-pointer">
            <div className="flex  lg:flex-row flex-col lg:justify-between lg:w-full lg:h-72">
              <img
                className="w-24 h-24 rounded-2xl mt-5"
                src={onboarding}
                alt="Image1"
              />
              <div className="lg:w-1/2">
                <h3 className="text-2xl font-bold text-[#08ce8a] mt-5 text-left ">
                  Onboarding{" "}
                </h3>
                <div className="leading-8 mt-4 text-[#1c1c57]">
                  Utilizing a digital platform that streamlines the onboarding
                  process and facilitates efficient KYC verification.
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center  cursor-pointer">
            <div className="flex  lg:flex-row flex-col lg:justify-between lg:w-full lg:h-72">
              <img
                className="w-24 h-24 rounded-3xl mt-5"
                src={aiPower}
                alt="Image1"
              />
              <div className="lg:w-1/2">
                <h3 className="text-2xl font-bold text-[#08ce8a] mt-5 text-left">
                  AI Powered{" "}
                </h3>
                <div className="leading-8 mt-4 text-[#1c1c57]">
                  Incorporating cutting-edge AI algorithms for precise
                  underwriting assessments and proactive risk management
                  strategies.
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center  cursor-pointer">
            <div className="flex  lg:flex-row flex-col lg:justify-between lg:w-full lg:h-72 gap-12">
              <div className="">
                <img
                  className="w-24 h-24 rounded-3xl mt-5"
                  src={cust}
                  alt="Image1"
                />
              </div>
              <div className="lg:w-1/2">
                <h3 className="text-2xl font-bold text-[#08ce8a] mt-5 text-left">
                  Customized Solutions{" "}
                </h3>
                <div className="leading-8 mt-4 text-[#1c1c57]">
                  Customized solutions for all industries and verticals.
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-[35px] h-96 shadow-3xl p-8 items-center flex justify-center  cursor-pointer">
            <div className="flex  lg:flex-row flex-col lg:justify-between lg:w-full lg:h-72">
              <img
                className="w-24 h-24 rounded-3xl mt-5"
                src={experts}
                alt="Image1"
              />
              <div className="lg:w-1/2">
                <h3 className="text-2xl font-bold text-[#08ce8a] mt-5 text-left">
                  Field Expertise{" "}
                </h3>
                <div className="leading-8 mt-4 pb-3 text-[#1c1c57]">
                  Led by a seasoned founding team equipped with extensive
                  expertise in both technology and business development,
                  ensuring a robust leadership foundation for the platform's
                  success.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
