import React from "react";
import Navbar from "./Navbar";
import Home from "./Home";
import HowItWorks from "./HowItWorks";
import Features from "./Features";
import Contact from "./Contact";
import OurTeam from "./OurTeam";
import Footer from "../common/Footer";
import Tackle from "./Tackle";

function MainComponent() {
  return (
    <>
      <Navbar />
      <Home />
      <HowItWorks />
      <Tackle />
      <Features />
      <OurTeam />
      <Contact />
      <Footer />
    </>
  );
}

export default MainComponent;
