import React from "react";
import stepsImage from "../assets/stepsImage.jpg";

const HowItWorks = () => {
  return (
    <>
      <div
        className="my-24 md:px-12 px-4 py-16 max-w-screen-2xl mx-auto bg-white shadow-teal-100 shadow-sm"
        id="working"
      >
        <div className=" text-center mt-3">
          <h2 className=" text-3xl font-bold text-black mb-6 leading-relaxed">
            Process for Credit Sign Up
          </h2>
        </div>
        <div className="grid md:grid-col-3 sm:grid-cols-2 grid-cols-1 item-start md:gap-12 gap-8">
          <div className="h-96 flex justify-center">
            <img
              className=" h-full w-96 rounded-3xl"
              src={stepsImage}
              alt="steps"
            />
          </div>
          <div className="h-96 flex justify-center md:justify-start">
            <div className="flex flex-col gap-12 w-3/4  h-full justify-center">
              <div>
                <h3 className="text-green-300 mb-2">Step 1.</h3>
                <p>Create a account by signing up.</p>
              </div>
              <div>
                <h3 className="text-green-300 mb-2">Step 2.</h3>
                <p>Transact on any partner merchant portal.</p>
              </div>
              <div>
                <h3 className="text-green-300 mb-2">Step 3.</h3>
                <p>
                  Enjoy credit for up to 14 days. 3% per month in case of delay.
                  No compounding, interest on interest etc
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
