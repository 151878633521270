import React from "react";

const Home = () => {
  return (
    // give bg here for end to end design
    <div
      className="md:px-12 p-16 max-w-screen-2xl mx-auto mt-16 gradientBg"
      id="home"
    >
      {/* give bg here for specific div design */}
      <div className="">
        {/* Banner Content */}
        <div className="sm:p-10 flex justify-center">
          <h2 className="md:text-5xl text-5xl font-extrabold text-[#1c1c57] mb-6 lg:leading-relaxed leading-normal p-8 text-center">
            Empowering Growth with Innovative Anchor-Led Financing Solutions
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Home;
