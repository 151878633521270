import React from "react";
import Logo from "../assets/Growfi Logo - Color 1x.png";
import Privacy from "../assets/policyFiles/privacyPolicy.pdf";
import TAC from "../assets/policyFiles/terms&conditions.pdf";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <div className="bg-white md:px-14 p-4 max-w-screen-2xl mx-auto">
      <div className="mt-12 bottom-0 flex-row gap-10">
        <div className="flex sm:flex-row flex-col justify-between gap-8 items-start border-t-2 border-b-2">
          <div className="space-y-4 mt-5 mb-3">
            <h4 className="text-xl">Pages</h4>
            <ul className="space-y-3">
              <Link
                to="home"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                className="block hover:text-green-400 cursor-pointer"
              >
                Home
              </Link>
              <Link
                to="about"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                className="block hover:text-green-400 cursor-pointer"
              >
                About Us
              </Link>
              <Link
                to="working"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                className="block hover:text-green-400 cursor-pointer"
              >
                How It Works
              </Link>
              <Link
                to="contact"
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-100}
                className="block hover:text-green-400 cursor-pointer"
              >
                Contact Us
              </Link>
              <li>
                <a
                  href={Privacy}
                  className="block hover:text-green-400 cursor-pointer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={TAC}
                  className="block hover:text-green-400 cursor-pointer"
                >
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>
          <div className="space-y-4 mt-5 mb-3">
            <h4 className="text-xl ">Support</h4>
            <ul className="space-y-3 ">
              <p className="hover:text-green-400 cursor-default">
                info@thegrowfi.com
              </p>
              <p className="hover:text-green-400 cursor-default">
                +91-9718971515
              </p>
              <p className="hover:text-green-400 cursor-default">
                Tech-Surin Solutions Private Limited
              </p>
              <p className="hover:text-green-400 cursor-default w-3/4">
                G-4 Kirtideep Building Commercial Complex Nangal Raya, New
                Delhi-46
              </p>
            </ul>
          </div>
        </div>
        <div className="flex flex-col justify-center text-center mt-5">
          <a
            href="/"
            className="text-2xl font-semibold flex items-center space-x-3 justify-center"
          >
            <img
              src={Logo}
              alt="Growfi"
              className="w-24 inline-block items-center"
            ></img>
          </a>
          <p className="mt-3">© Copyright 2024 - GrowFi. All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
