import React, { useState } from "react";

const ContactForm = () => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  return (
    <>
      <div className="my-8 bg-white rounded-lg p-8 items-center flex justify-center shadow-md shadow-gray-200 border border-gray-300 font-semibold">
        <form
          id="myForm"
          className="w-full max-w-lg"
          hidden={isFormSubmitted}
          onSubmit={(e) => {
            e.preventDefault();
            document.getElementById("myForm").reset();
            setIsFormSubmitted(true);
          }}
        >
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full lg:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-name"
              >
                Name
              </label>
              <input
                required
                className="appearance-none block w-full bg-white text-black border border-gray-200 py-3 px-4 mb-3 rounded-lg leading-tight focus:outline-none focus:bg-white"
                id="grid-name"
                type="text"
                placeholder="Enter your Name"
              />
            </div>
            <div className="w-full lg:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-email"
              >
                Email
              </label>
              <input
                required
                className="appearance-none block w-full bg-white text-black border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-email"
                type="email"
                placeholder="Enter your Email"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full lg:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-phone"
              >
                Phone Number
              </label>
              <input
                required
                className="appearance-none block w-full bg-white text-black border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-phone"
                type="text"
                placeholder="Enter your Phone Number"
              />
            </div>
            <div className="w-full lg:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-subject"
              >
                Subject
              </label>
              <input
                required
                className="appearance-none block w-full bg-white text-black border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-subject"
                type="text"
                placeholder="Enter Subject"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-center"
                htmlFor="grid-message"
              >
                message
              </label>
              <textarea
                required
                className="h-36 max-w-full appearance-none block w-full bg-white text-black border border-gray-200 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-message"
                type="textarea"
                placeholder="How can we help you?"
              />
            </div>
          </div>
          <div className="flex justify-center">
            <button
              className="px-5 py-4 border border-black rounded-lg bg-[#1c1c57] text-white"
              type="submit"
            >
              {" "}
              Send Message
            </button>
          </div>
        </form>
        {isFormSubmitted && (
          <div className="w-full h-48 lg:h-96  max-w-lg flex items-center">
            <div className="mx-auto px-5 py-5 border rounded-xl bg-[#1c1c57] text-white text-center text-xl">
              <h4>Your message has been submitted.</h4>
              <h4>We will get back to you within 24-48 hours.</h4>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactForm;
